<template>
   <div 
      @touchmove="handleMiniStackTouchStart"
      @touchend="handleMiniStackTouchEnd"  
   >
     <div v-for="( img, key ) in imageUrls" :key="key">
       <img :src="img || './noimg.png'" :class="
        [
          'ministack-card',
          isBeingDraggedRef && key == imageUrls.length -1 ? 'ministack-top-slide' : '',
        ]
       " :style="
       key < 2 ? {
         'right': 33+key*5+'px',
         'bottom': 33+key*5+'px'
         }:
         {
         'right': 33+2*6+'px',
         'bottom': 33+2*6+'px'
         }
         "/>
     </div>
   </div>
</template>

<script>
import { ref } from '@vue/reactivity';


export default {
  name: "MiniStack",
  props: ["imageUrls"],
  emits: ["ministackswiped"],
  components: { },
  setup(props,context) {

    const isBeingDraggedRef = ref(false);

    const handleMiniStackTouchStart = ()=>{
      isBeingDraggedRef.value = true;
    }

    const handleMiniStackTouchEnd = ()=>{
      if (isBeingDraggedRef.value){
        isBeingDraggedRef.value = false;
        context.emit('ministackswiped');
      }
    }
    

    return {isBeingDraggedRef,handleMiniStackTouchStart,handleMiniStackTouchEnd};
  },
};
</script>
